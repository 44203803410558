<template>
    <div class="">
        <div data-role="page" data-theme="f">
    <div data-role="content" >
        <div class="login-input-box">
            <div class="head-img">
                <div class="phone">我的头像</div>
                <div id="preview">
                    <img id="imghead" src="../../../assets/img/personal/houses.jpeg"  />
                </div>
                <input type="file" data-role="none" class="hidden" onchange="previewImage(this)" />
                <!-- <a class="a-file" href="javascript:void(0)" type="file" data-role="none">+</a> -->
            </div>
            <div class="line1">      
                <img src="../../../assets/img/personal/user.png">
                <!-- <input type="text" class="user" readonly :value=list.name onfocus="if(value=='用户名') {value=''}" onblur="if (value=='') {value='用户名'}" data-role="none"> -->
                 <input type="text" class="user" readonly :value=username onfocus="if(value=='用户名') {value=''}" onblur="if (value=='') {value='用户名'}" data-role="none">
            </div>
            <div class="line2">
                <img src="../../../assets/img/personal/phone.png">
                <!-- <input type="text" class="lock" readonly :value=list.username onfocus="if(value=='手机号码') {value=''}" onblur="if (value=='') {value='手机号码'}" data-role="none"> -->
                <input type="text" class="lock" readonly :value=password onfocus="if(value=='手机号码') {value=''}" onblur="if (value=='') {value='手机号码'}" data-role="none">
            </div>
            <div class="myTeam" @click="team">我的下线</div>
            <div class="myTeam" @click="quit">退出登录</div>
        </div>
    </div>
</div>
    </div>
   
</template>

<script >
import { onMounted, ref } from '@vue/runtime-core';
//引入路由函数
import { useRouter } from "vue-router";
export default {
    name: "MyPersonal",
    setup() {
        //初始化路由
        const router = useRouter()
        const  username =  window.localStorage.getItem("username");
        const  password =  window.localStorage.getItem("password");
        const team = () => {
            router.push("/agent")
        }
        const quit = () => {
             router.push("/login")
        }

        return  {
            username,password,team,quit
        }
    },
}
</script>

<style scoped>
    @import '../../../assets/css/theme.css';
    @import '../../../assets/css/style.css'; 

    .phone {
            margin-bottom: 29px;
             font-size: 21px;
             color: #574f4f;
    }
      .login-input-box {
    width: 80%;
    margin: auto;
    position: fixed;
    top: 34%;
    left: 50%;
    transform: translate(-50%,-50%)
    }
    .myTeam:hover {
        cursor: pointer;
    }
    .myTeam {
        color: #886464;
        font-size: 20px;
        text-align: right;
        padding-top: 24px;
        width: 80px;
        margin-left: 211px;
    }
    .head-img {
    position: relative;
    margin-top: 156px;
    text-align: center;
    }
</style>