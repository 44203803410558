import { request } from "./request";


// 楼盘推荐
export function getrecommendation(whj_toPage) {
    return request({
        // url: '/Api/Index/getCases/limit/12/'
        url: "/sys/sys/houses/housesPage/",
        params: {
            whj_toPage,
        }
    })
}

// 楼盘详情
export function getDetail(id) {
    return request({
        //    url: '?s=/Api/Gzj/housesLoad',
        url: '/sys/sys/houses/housesLoad/',
        params: {
            id,
        }
    })
}

//更多楼盘
export function getmorehourse(whj_toPage) {
    return request({
        url: '/sys/sys/houses/housesPage/',
        params: {
            whj_toPage,
        }
    })
}

//楼盘搜索
export function getSearch(city) {
    return request({
        url: '/sys/sys/houses/housesPage?city=' + city,

    })
}

//下拉楼盘
export function getsorthouses() {
    return request({
        url: '/sys/sys/classify/classifyAll',
    })
}


//查询楼盘全部，通过分类id
export function getsorthousesByHousesId(classifyId) {
    return request({
        url: '/sys/sys/houses/housesPage?classifyId=' + classifyId,
    })
}


//更多详情
export function getCateDetail(id) {
    return request({
        url: '/sys/sys/houses/housesLoad/',
        params: {
            id,
        }
    })
}

//个人信息
export function getinformation(id) {
    return request({
        url: '/sys/sys/user/userLoad/',
        params: {
            id,
        }
    })
}

//我的团队
// export function getagent(data) {
//     return request({
//         url: '/sys/appoint/appointById/',
//         method: "POST",
//         data
//     })
// }
export function getagent(userId) {
    return request({
        url: '/sys/sys/appoint/appointById?userId=' + userId,
        method: "GET",
    })
}



//登录
export function getlogin(username, password) {
    return request({
        url: '/sys/sys/user/in',
        params: {
            username,
            password
        }
    })
}

//注册
// //推荐我要报名
// export function getappy(params) {
//     return request({
//         url: '/sys/sys/appoint/appointIndexAdd',
//         method: "GET",
//         params
//     })
// }

// //更多我要报名
// export function getCateappy(params) {
//     return request({
//         url: '/sys/sys/appoint/appointIndexAdd',
//         method: "GET",
//         params
//     })
// }


//推荐我要报名
export function getappy(params) {
    return request({
        url: '/sys/sys/appoint/appointIndexAdd',
        method: "GET",
        params
    })
}

//更多我要报名
export function getCateappy(params) {
    return request({
        url: '/sys/sys/appoint/appointIndexAdd',
        method: "GET",
        params
    })
}


//点击分享
export function getShareWx(params) {
    return request({
        url: '/sys/sys/wx/param?url=' + params,
        method: "GET"
    })
}